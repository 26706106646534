<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="12"
        class="d-flex flex-column align-items-center background justify-content-center"
      >
        <div class="d-flex align-items-center">
          <!-- <b-img
            fluid
            :src="require('@/assets/images/logo/logo.png')"
            class=""
            width="60"
            alt="Login V2"
          /> -->
          <h3
            class="text-uppercase text-center text-dark font-weight-bolder mt-2 mb-1 my-sm-3"
          >
            {{ $t('chooseUserType') }}
          </h3>
        </div>

        <b-row class="mb-1">
          <b-col
            sm="12"
            lg="6"
            class="d-flex justify-content-center text-center"
          >
            <!-- Regresar a 30rem cuando la tarjeta de proveedor sea visible -->
            <b-card
              :img-src="require('@/assets/images/login/customer_2.jpg')"
              img-alt="Image"
              img-top
              tag="article"
              img-width="100"
              style="max-width: 30rem;"
              class="text-center mb-1 mb-sm-0 cursor-pointer"
              @click="$router.push({ name: 'auth-register' })"
            >
              <b-card-title class="mb-1 mb-sm-3">
                <b-badge variant="primary">
                  <h3 class="text-white font-weight-bolder mb-0">
                    {{ $t('constructor') }}
                  </h3>
                </b-badge>
              </b-card-title>
              <b-card-text class="min-height-sm-up">
                <strong>Compra</strong> a través de nuestra plataforma, monitorea tu obra, registra ingresos, egresos, genera explosiones de insumos y obten reportes avanzados
              </b-card-text>

              <!-- <div class="d-flex  align-items-end">
                <b-button
                  href="#"
                  block
                  variant="success"
                  :to="{ name: 'auth-register' }"
                >
                  {{ $t('select') }}
                </b-button>
              </div> -->
            </b-card>
          </b-col>
          <b-col
            sm="12"
            lg="6"
            class="d-flex justify-content-center text-center"
          >
            <b-card
              :img-src="require('@/assets/images/login/supplier_2.jpeg')"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 30rem;"
              class="text-center mb-1 mb-sm-0 cursor-pointer"
              img-width="100"
              @click="$router.push({ name: 'registerSupplierWithoutAccount' })"
            >
              <b-card-title class="mb-1 mb-sm-3">
                <b-badge variant="primary">
                  <h3 class="text-white font-weight-bolder mb-0">
                    {{ $t('materialSupplier') }}
                  </h3>
                </b-badge>
              </b-card-title>
              <b-card-text class="min-height-sm-up">
                <strong>Participa</strong> en nuestra red de ventas, ofrece tus productos de construcción y <strong>genera ventas</strong> todos los dias
              </b-card-text>

              <!-- <div class="d-flex align-items-end">
                <b-button
                  href="#"
                  block
                  variant="success"
                  :to="{ name: 'registerSupplierWithoutAccount' }"
                >
                  {{ $t('select') }}
                </b-button>
              </div> -->
            </b-card>
          </b-col>
        </b-row>
        <div class="text-center w-100">
          <span
            class="text-primary cursor-pointer"
            block
            @click="$router.push({ name: 'auth-login' })"
          >
            {{ $t('alreadyHaveAnAccount') }}
          </span>
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions } from 'vuex'
import { BRow, BCol, VBTooltip } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      logo: require('@/assets/images/logo/logo.png'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.signIn({ email: this.userEmail, password: this.password })
            .then(response => {
              document.documentElement.style.setProperty(
                '--secondary-custom',
                response.secondary_color,
              )
              document.documentElement.style.setProperty(
                '--primary-custom',
                response.primary_color,
              )
              const userData = response
              useJwt.setToken(response.token)
              useJwt.setRefreshToken(response.refresh_token)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData))
                .then(() => {
                  // this.$router.go()
                })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.messages,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.background {
  background-color: rgba(240, 239, 235, 0.5);
  // background: url('../../assets/images/login/register.jpg');
  // background-size: cover;
  // background-position: center center;
  // min-height: 100%;
  // box-shadow: inset 0 0 0 2000px rgba(240,239,235, 0.5);
}
@media (min-width: 576px) {
  .min-height-sm-up {
    min-height: 105px;
  }
}
</style>
